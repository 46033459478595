import React from "react"
import Breadcrumb from "./../slices/Breadcrumb"

export default ({ data, breadcrumb }) => {
  const { hero_image } = data
  return (
    <div className="hero hero--edit">
      <div className="[ container -pinched ]">
        <div className="hero__inner">
          <Breadcrumb data={breadcrumb} />
          <div className="hero-holder">
            {hero_image.thumbnails && (
              <picture>
                <source
                  srcSet={hero_image.thumbnails.tiny.url}
                  media="(max-width: 420px)"
                />
                <source
                  srcSet={hero_image.thumbnails.small.url}
                  media="(max-width: 568px)"
                />
                <source
                  srcSet={hero_image.thumbnails.medium.url}
                  media="(max-width: 768px)"
                />
                <source
                  srcSet={hero_image.thumbnails.large.url}
                  media="(max-width: 980px)"
                />
                <source
                  srcSet={hero_image.thumbnails.huge.url}
                  media="(max-width: 1200px)"
                />
                <source
                  srcSet={hero_image.thumbnails.mega.url}
                  media="(max-width: 1600px)"
                />
                <img
                  src={hero_image.url}
                  alt=""
                  className="hero__image"
                  loading="lazy"
                />
              </picture>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
