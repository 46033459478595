import React, { Fragment } from "react"
import { Link } from "gatsby"
import linkResolver from "./../../utils/linkResolver"
import { IconButtonLink } from "./../buttons"
import { IconArrowRight } from "./../svg"

export default (props) => {
  let { data, className } = props
  const dataLength = data.length - 1
  const prevPage = dataLength - 1

  if (!className || className === undefined) {
    className = ""
  }

  return (
    <div className={`breadcrumb ${className}`}>
      <div className="breadcrumb__inner">
        <IconButtonLink
          to={linkResolver(data[prevPage])}
          className="-small breadcrumb__button"
        >
          <IconArrowRight className="[ icon -flip icon--breadcrumb ]" />
        </IconButtonLink>
        {data.map((item, index) => {
          if (index === 0) {
            return (
              <Fragment key={index}>
                <Link
                  to={linkResolver(item)}
                  className="breadcrumb__link"
                  activeClassName="active"
                >
                  <span>{item.data.title.text}</span>
                </Link>
                <span className="breadcrumb__divider">/</span>
              </Fragment>
            )
          }
          if (index !== dataLength) {
            return (
              <Fragment key={index}>
                <Link
                  to={linkResolver(item)}
                  className="breadcrumb__link"
                  activeClassName="active"
                >
                  <span>{item.data.title.text}</span>
                </Link>
                <span className="breadcrumb__divider">/</span>
              </Fragment>
            )
          }

          return (
            <Link
              key={index}
              to={linkResolver(item)}
              className="breadcrumb__link"
              activeClassName="active"
            >
              <span>{item.data.title.text}</span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
