import React from "react"
import { IconShop } from "../svg"

export default ({ data }) => {
  const latestFav = data.node.data

  return (
    <div className="favourite">
      <a
        className="favourite__link"
        href={latestFav.link.url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="favourite__inner">
          <div className="favourite__media">
            <img
              src={latestFav.image.url}
              alt=""
              className="favourite__image"
              loading="lazy"
            />
            <div className="favourite__overlay">
              <div className="overlay-icon">
                <IconShop className="icon icon--shop" />
              </div>
            </div>
          </div>
          <div className="favourite__content">
            <p className="favourite__title link--multiline">
              <span>{latestFav.title[0].text}</span>
            </p>
            <p className="favourite__price">
              <span>{latestFav.price}</span>
            </p>
            <p className="favourite__retailer">{latestFav.retailer}</p>
          </div>
        </div>
      </a>
    </div>
  )
}
