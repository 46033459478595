exports.buildBreadcrumb = function buildBreadcrumb(items) {
  const breadcrumb = []

  items.forEach((item) => {
    if (item) {
      breadcrumb.push(item)
    }
  })

  return breadcrumb
}
