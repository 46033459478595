import React from "react"
import { Link } from "gatsby"

export default (props) => {
  const { to, state, children, className } = props

  return (
    <Link className={`icon-button ${className}`} to={to} state={{ state }}>
      {children}
    </Link>
  )
}
