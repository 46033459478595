import React from "react"

export default ({ props, className = "" }) => {
  const { title, hero_image, hero_text_colour } = props

  return (
    <>
      <div className={`hero ${className}`}>
        <div className="hero__inner container">
          <h1
            className={`[ title -no-margin -${
              hero_text_colour ? hero_text_colour.toLowerCase() : "black"
            } ]`}
          >
            {title.text}
          </h1>
        </div>
        {hero_image.thumbnails && (
          <picture>
            <source
              srcSet={hero_image.thumbnails.tiny.url}
              media="(max-width: 420px)"
            />
            <source
              srcSet={hero_image.thumbnails.small.url}
              media="(max-width: 568px)"
            />
            <source
              srcSet={hero_image.thumbnails.medium.url}
              media="(max-width: 768px)"
            />
            <source
              srcSet={hero_image.thumbnails.large.url}
              media="(max-width: 980px)"
            />
            <source
              srcSet={hero_image.thumbnails.huge.url}
              media="(max-width: 1200px)"
            />
            <source
              srcSet={hero_image.thumbnails.mega.url}
              media="(max-width: 1600px)"
            />
            <img
              src={hero_image.url}
              alt=""
              className="hero__image"
              loading="lazy"
            />
          </picture>
        )}

        {!hero_image.thumbnails && (
          <img
            src={hero_image.url}
            alt=""
            className="hero__image"
            loading="lazy"
          />
        )}
      </div>
    </>
  )
}
