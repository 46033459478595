import React from "react"
import { Link } from "gatsby"
import { getMonth } from "../../utils/getMonth"
import linkResolver from "../../utils/linkResolver"

export default ({ data, className }) => {
  let date
  const { hero_image, title, category, snippet, published_date } = data.data

  if (published_date) {
    const editDate = new Date(published_date)
    const editDay = editDate.getDate()
    const editMonth = editDate.getMonth()
    const editYear = editDate.getFullYear()
    date = `${editDay} ${getMonth(editMonth)} ${editYear}`
  }

  return (
    <div className={`edit-pod ${className}`}>
      <Link className="edit-pod__link" to={linkResolver(data)}>
        <div className="edit-pod__inner">
          <div className="edit-pod__media">
            {hero_image && (
              <img
                data-src={hero_image.url}
                alt={title.text}
                className="edit-pod__image"
                src={hero_image.url}
                loading="lazy"
              />
            )}
          </div>
          <div className="edit-pod__content">
            {category && (
              <p className="edit-pod__category">
                {category.document.data.title.text}
              </p>
            )}
            {title && (
              <p className="edit-pod__title link--multiline">
                <span>{title.text}</span>
              </p>
            )}
            {snippet && <p className="edit-pod__snippet">{snippet.text}</p>}
            {published_date && <p className="edit-pod__date">{date}</p>}
          </div>
        </div>
      </Link>
    </div>
  )
}
