import React from "react"

export default ({ children, onClick, className, name }) => {
  if (!className || className === undefined) {
    className = ""
  }

  return (
    <button
      onClick={onClick}
      className={`icon-button ${className}`}
      name={name}
    >
      {children}
    </button>
  )
}
