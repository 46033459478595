import React, { useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts"
import linkResolver from "./../utils/linkResolver"
import { buildBreadcrumb } from "./../utils/buildBreadcrumb"
import Breadcrumb from "./../components/slices/Breadcrumb"
import { EditPod } from "./../components/pods"
import { Hero } from "./../components/hero"

// Query for the Blog Home content in Prismic
export const query = graphql`
  query EditsQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            seotitle
            seodescription
            title {
              text
            }
          }
          id
          url
          type
        }
      }
    }
    allPrismicLatestEditsListing {
      edges {
        node {
          data {
            seotitle
            seodescription
            title {
              text
            }
            hero_text_colour
            hero_image {
              url(imgixParams: { q: 90 })
              thumbnails {
                huge {
                  url(imgixParams: { q: 90 })
                }
                medium {
                  url(imgixParams: { q: 90 })
                }
                mega {
                  url(imgixParams: { q: 90 })
                }
                small {
                  url(imgixParams: { q: 90 })
                }
                tiny {
                  url(imgixParams: { q: 90 })
                }
                large {
                  url(imgixParams: { q: 90 })
                }
              }
            }
          }
          id
          uid
          type
        }
      }
    }
    allPrismicCategory {
      edges {
        node {
          data {
            title {
              text
            }
            hero_image {
              url(imgixParams: { q: 90 })
            }
            name
          }
          id
          uid
          type
        }
      }
    }
    allPrismicEdit(
      limit: 10
      sort: { fields: data___published_date, order: DESC }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            hero_image {
              url(imgixParams: { q: 90 })
            }
            snippet {
              text
            }
            category {
              __typename
              document {
                ... on PrismicCategory {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          first_publication_date
          uid
          id
          type
        }
      }
    }
  }
`

export default ({ data }, location) => {
  const {
    allPrismicEdit,
    allPrismicLatestEditsListing,
    allPrismicHomepage,
    allPrismicCategory,
  } = data
  const allEdits = allPrismicEdit.edges
  const homeData = allPrismicHomepage.edges[0].node
  const editListingData = allPrismicLatestEditsListing.edges[0].node

  let allCategories = []
  let thisBreadcrumb = buildBreadcrumb([homeData, editListingData])

  for (var i = 0; i < allPrismicCategory.edges.length; i++) {
    let hasMatch = false
    const currentCategory = allPrismicCategory.edges[i].node

    for (var j = 0; j < allCategories.length; j++) {
      if (allCategories[j].data.name === currentCategory.data.name) {
        hasMatch = true
      }
    }

    if (!hasMatch) {
      allCategories.push(currentCategory)
    }
  }

  const categoryClick = (url, e) => {
    e.preventDefault()
    const state = { offset: window.pageYOffset }
    navigate(url, { state })
  }

  useEffect(() => {
    if (location.state) {
      alert(`has state`)
      window.scroll({
        top: 0,
        left: 0,
      })
    }
  }, [location])

  return (
    <Layout>
      <Helmet>
        <title>
          {editListingData.seotitle
            ? editListingData.seotitle
            : homeData.data.seotitle}
        </title>
        <meta
          name="description"
          content={
            editListingData.seodescription
              ? editListingData.seodescription
              : homeData.data.seodescription
          }
        />
      </Helmet>
      <Hero props={editListingData.data} />
      <div className="[ container -pinched ]">
        <Breadcrumb className="border-bottom" data={thisBreadcrumb} />
        <div className="category-menu">
          <div className="category-menu__inner">
            <Link
              className="category-menu__item button"
              activeClassName="active"
              to={linkResolver(editListingData)}
              onClick={(e) => categoryClick(linkResolver(editListingData), e)}
            >
              All categories
            </Link>
            {allCategories.map((category, index) => (
              <Link
                key={index}
                className="category-menu__item button"
                to={linkResolver(category)}
                onClick={(e) => categoryClick(linkResolver(category), e)}
              >
                {category.data.name}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="latest-edits">
        <div className="[ container -pinched ]">
          <div className="latest-edits__grid">
            {allEdits.map((edit, index) => {
              let editItem = edit.node
              return <EditPod data={editItem} key={index} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
